import React, { useEffect, useState } from "react"
import { CookieBanner, Footer, Header, HelpChat } from "../../components"
import Prejoin_DE from "../../files/Prejoin_DE.pdf"
import Prejoin_EN from "../../files/Prejoin_EN.pdf"
import { getLanguage } from "../../utility/language"
import style from "./style.module.css"
import "../../App.css"

export default function Support() {
  const lang = getLanguage().includes("de")

  const [isDe, setIsDe] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsDe(lang)
    setIsLoaded(true)
  }, [lang])

  useEffect(() => {
    setIsDe(lang)
  }, [lang])

  //deutsche texte setzen
  const titleDe = "Technischer Support"
  const text1De =
    "<b>Welchen Browser sollten Sie benutzen?</b>\n" +
    "\n" +
    "Die Konferenz-Plattform ist <b>nur</b> mit den Browsern: Google Chrome und Edge Chromium erreichbar. Stellen Sie sicher, dass eine aktuelle Version dieser Browser installiert ist.\n" +
    "So können Sie Ihren Browser aktualisieren:\n" +
    "\n" +
    "Google Chrome: Klicken Sie auf das Einstellungs-Icon (3 Punkte) in der oberen rechen Ecke ihres Browsers. Wählen Sie den Menüpunkt <b>Hilfe</b> und anschließend <b>Über Google Chrome</b>. Sie können nun die Aktualisierung abschließen.\n" +
    "\n" +
    "Microsoft Edge: Klicken Sie auf das Einstellungs-Icon (3 Punkte) in der oberen rechen Ecke ihres Browsers. Wählen Sie den Menüpunkt <b>Einstellungen</b> sowie <b>Infos zu Microsoft Edge</b>. Sie können nun die Aktualisierung abschließen.\n" +
    "\n" +
    "<b>So erhalten Sie die bestmögliche Verbindung:</b>\n" +
    "\n" +
    "Die beste Verbindung bietet eine LAN-Verbindung. Wählen Sie, wenn möglich, eine Verbindung aus dem Homeoffice/Remote Work, da es aufgrund technischer Regulierungen innerhalb der Firmennetzwerke zu Problemen kommen kann. Falls Ihnen nur ein Firmennetzwerk zur Verfügung steht, nutzen Sie das Gäste-LAN (notfalls Gäste WLAN).\n" +
    "\n" +
    "<b>Welche Up- und Downloadgeschwindigkeit ist erforderlich?</b>\n" +
    "\n" +
    "Für einen störungsfreien Ablauf der Session ist eine Up- und Downloadgeschwindigkeit von mindestens 5Mbit erforderlich.\n" +
    "\n" +
    "<b>Sie können die anderen Teilnehmer*innen nicht hören?</b>\n" +
    "\n" +
    "Stellen Sie sicher, dass die Lautstärke Ihres Computers nicht zu leise eingestellt oder deaktiviert ist. Während einer Session können Sie zudem am unteren Bildschirmrand über das mittig platzierte Mikrofonsymbol kontrollieren, ob Sie den richtigen Lautsprecher ausgewählt haben. Durch einen Klick auf das Einstellungs-Icon (3 Punkte) innerhalb der einzelnen Kacheln anderer Teilnehmer*innen haben Sie weiterhin die Möglichkeit, die Lautstärke einzelner Personen anzupassen.\n" +
    "\n" +
    "<b>Die anderen Teilnehmer*innen können Sie nicht hören?</b>\n" +
    "\n" +
    "Überprüfen Sie, ob Sie ihrem Browser den Zugriff auf das Mikrofon erlaubt haben. Klicken Sie hierfür auf das Schloss-Icon in der Adresszeile und wählen Sie neben dem Mikrofon-Icon den Menüpunkt Zulassen. Sollten Sie die anderen Teilnehmer*innen noch immer nicht hören, dann klicken Sie auf das am unteren Bildschirmrand mittig platzierte Mikrofonsymbol und wählen das richtige Mikrofon aus. Stellen sie zudem sicher, dass Ihr Mikrofon funktioniert. Sollte auch dies nicht funktionieren, dann verwenden Sie ein externes Mikrofon (Bsp. Headset mit Kabelverbindung).\n" +
    "\n" +
    "Eine ausführlichere Anleitung finden Sie hier: "
  const text2De =
    "<b>Die anderen Teilnehmer*innen können Sie nicht sehen?</b>\n" +
    "\n" +
    "Prüfen Sie, ob Sie Ihrem Browser den Zugriff auf Ihre Webcam erlaubt haben. Klicken Sie hierfür auf das Schloss-Icon in der Adresszeile und wählen Sie neben dem Kamera-Icon den Menüpunkt Zulassen. Sollten Sie die anderen Teilnehmer*innen noch immer nicht sehen, dann klicken Sie auf das am unteren Bildschirmrand mittig platzierte Kamerasymbol und wählen Sie die richtige Webcam aus.\n" +
    "\n" +
    "Wenn Sie weiterhin Probleme mit der Nutzung der Webcam haben, finden Sie hier eine ausführlichere Anleitung: "
  const text3De =
    "<b>So können Sie Ihren Bildschirm teilen:</b>\n" +
    "\n" +
    "Klicken Sie hierfür auf das Bildschirm-Icon in der linken unteren Ecke Ihres Bildschirmrandes. In dem nun erscheinenden Popup-Fenster können Sie die Inhalte auswählen, die Sie mit den anderen Teilnehmer*innen teilen möchten und zudem den Audiokanal Ihres Computers freigeben.\n" +
    "\n" +
    "<b>So können Sie einstellen, welche Teilnehmer*innen für sie sichtbar sind:</b>\n" +
    "\n" +
    "Sie möchten eine einzelne Person sehen?\n" +
    "Dann klicken Sie auf die Kachel der gewünschten Teilnehmerin oder des gewünschten Teilnehmers. Sie sehen nun lediglich die ausgewählte Person.\n" +
    "Sie möchten alle Teilnehmer*innen sehen?\n" +
    "Klicken Sie am unteren rechten Bildschirmrand auf das Icon mit den vier Quadraten. Sie sehen nun alle Teilnehmer*innen.\n" +
    "\n" +
    "<b>So können Sie mit anderen Teilnehmer*innen während einer Session chatten:</b>\n" +
    "\n" +
    "Klicken Sie auf das Textfenstersymbol in der linken unteren Ecke Ihres Bildschirms und öffnen Sie so das Chat-Fenster. Sie können sich nun mit anderen Teilnehmer*innen austauschen.\n" +
    "\n" +
    "<b>Sie werden beim Öffnen des Meetings über die Jisti App nach einem Passwort gefragt?</b>\n" +
    "\n" +
    "Geben Sie den Meeting-link in den Browser Ihres Handys ein. Sie werden nun gefragt, ob Sie das Meeting über die App öffnen möchten. Bestätigen Sie dies."
  //englische texte setzen
  const titleEn = "Technical Support"
  const text1En =
    "<b>Which browser should you use?</b>\n" +
    "\n" +
    "The conference platform is <b>only</b> accessible with the browsers: Google Chrome and Edge Chromium. Make sure you already installed the latest version of these browsers.\n" +
    "How you can update your browser:\n" +
    "\n" +
    "Google Chrome: Click on the settings icon (3 dots) in the upper right corner of your browser. Select the menu tab: <b>help</b> and further: <b>About Google Chrome</b>. Now you can complete your update.\n" +
    "\n" +
    "Microsoft Edge: Click on the settings icon (3 dots) in the upper right corner of your browser. Select the menu tab: <b>settings</b> and further: <b>Information about Microsoft Edge</b>. Now you can complete your update.\n" +
    "\n" +
    "<b>How to get the best possible connection:</b>\n" +
    "\n" +
    "To get the best possible connection for your session please use a LAN connection. If possible, choose a connection from your home office / remote work, since technical regulations within the company networks can lead to problems. If you only have one company network, use the guest LAN (if necessary, guest WLAN).\n" +
    "\n" +
    "<b>What is the required upload and download speed?</b>\n" +
    "\n" +
    "An upload and download speed of at least 5Mbit is required for the session to run smoothly.\n" +
    "\n" +
    "<b>You cannot hear the other participants?</b>\n" +
    "\n" +
    "Make sure your computer's volume is not turned up too low or is turned off. During a session, you can use the microphone symbol at the bottom of the screen to check whether you have selected the correct speaker. Furthermore you can click on the settings icon (3 dots) within the individual selection field of other participants in order to adjust the volume of individual people.\n" +
    "\n" +
    "<b>The other participants can’t hear you?</b>\n" +
    "\n" +
    "Check whether you have allowed your browser to access the microphone. Therefor, click on the lock icon in the address line and select the menu tab: <b>allow</b> next to the microphone icon. If you still cannot hear the other participants, click on the microphone symbol at the bottom of the screen and select the correct microphone. Also make sure your microphone is working. If this does not work either, use an external microphone (e.g. headset with cable connection).\n" +
    "\n" +
    "You can find more detailed instructions here: "
  const text2En =
    "<b>You can't see the other participants?</b>\n" +
    "\n" +
    "Check you have allowed your browser to access your webcam. Therefor, click on the lock icon in the address line and select the menu tab: <b>allow</b> next to the camera icon. If you still cannot see the other participants, click on the camera symbol at the bottom of the screen and select the correct webcam.\n" +
    "\n" +
    "If you still have problems using the webcam, you find more detailed instructions here: "
  const text3En =
    "<b>How do I share my screen?</b>\n" +
    "\n" +
    "Click on the screen icon in the lower left corner of your screen. Within the now appearing popup window you can select the content you want to share with other and furthermore share the audio channel on your computer.\n" +
    "\n" +
    "<b>This is how you can set which participants are visible to you:</b>\n" +
    "\n" +
    "Would you like to see a single person?\n" +
    "Directly click on the selection field of the participant you want to see: You now only see the selected person.\n" +
    "\n" +
    "Would you like to see all participants?\n" +
    "Click on the icon with the four squares in the lower right corner of the screen. You can now see all participants.\n" +
    "\n" +
    "<b>How you can chat with other participants during a session:</b>\n" +
    "\n" +
    "Click on the text field icon in the lower left corner of your screen to open the chat window. You can now exchange ideas with other participants.\n" +
    "\n" +
    "<b>Are you asked for a password when opening the meeting via the Jisti app?</b>\n" +
    "\n" +
    "Enter the meeting link in your mobile phone browser. You will now be asked whether you want to open the meeting via the app. Confirm."

  const PdfLink =
    getLanguage() === "de_DE"
      ? () => (
          <a
            onClick={() => {
              const link = document.createElement("a")
              link.download = "Prejoin_DE.pdf"
              link.href = Prejoin_DE
              link.type = "application/pdf"
              link.click()
            }}
          >
            Prejoin-DE
          </a>
        )
      : () => (
          <a
            onClick={() => {
              const link = document.createElement("a")
              link.download = "Prejoin_EN.pdf"
              link.href = Prejoin_EN
              link.type = "application/pdf"
              link.click()
            }}
          >
            Prejoin-EN
          </a>
        )

  return (
    <div>
      <CookieBanner />
      <Header solid />
      <title>Bühler Virtual World</title>

      <HelpChat id="dcf838e2-1037-4b02-aa64-1b3a1984d772" />
      {isLoaded && (
        <div className={style.container}>
          <h1 className={style.title}>{isDe ? titleDe : titleEn}</h1>
          <pre
            className={style.paragraph}
            dangerouslySetInnerHTML={{ __html: isDe ? text1De : text1En }}
          ></pre>
          <div className={style.linkContainer}>
            <PdfLink />
          </div>

          <pre
            className={style.paragraph}
            dangerouslySetInnerHTML={{ __html: isDe ? text2De : text2En }}
          ></pre>
          <div className={style.linkContainer}>
            <PdfLink />
          </div>

          <pre
            className={style.paragraph}
            dangerouslySetInnerHTML={{ __html: isDe ? text3De : text3En }}
          ></pre>
        </div>
      )}

      <Footer />
    </div>
  )
}
